import PropTypes from 'prop-types';
import styles from './SkillImage.module.scss';

const SkillImage = ({ image }) => {
  return (
    <span className={styles.SkillImage}>
      {image ? (
        <span
          className={styles.image}
          style={{ backgroundImage: `url(/images/skills/${image})` }}
        />
      ) : (
        <span className={`${styles.image} ${styles.icon} `} />
      )}
    </span>
  );
};

SkillImage.propTypes = {
  image: PropTypes.string
};

export default SkillImage;
