import PropTypes from 'prop-types';
import styles from './DropdownListItem.module.scss';
import CheckboxGroup from '../CheckboxGroup/CheckboxGroup';

const DropdownListItem = ({
  checked,
  indeterminate,
  option,
  text,
  onChange: handleChange
}) => {
  return (
    <li className={styles.DropdownListItem}>
      <CheckboxGroup
        checked={checked}
        indeterminate={indeterminate}
        onChange={handleChange}
        value={option}
        text={text}
      />
    </li>
  );
};

DropdownListItem.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  option: PropTypes.string,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DropdownListItem;
