import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import Icon from '../../atoms/Icon/Icon';

const Button = forwardRef(({
  onClick: handleClick, text, icon, rotateIcon, isClicked
}, ref) => {
  const iconClassNames = `
    ${styles.Icon}
    ${rotateIcon && isClicked ? styles.rotate : ''}
  `;

  return (
    <button
      className={styles.Button}
      onClick={handleClick}
      type="button"
      ref={ref}
    >
      {text || null}

      {icon ? (
        <Icon
          name={icon}
          className={iconClassNames}
        />
      ) : null}
    </button>
  );
});

Button.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  rotateIcon: PropTypes.bool,
  isClicked: PropTypes.bool
};

export default Button;
