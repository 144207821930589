import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SpeechBubble.module.scss';
import Chevrons from '../../molecules/Chevrons/Chevrons';
import Image from '../../atoms/Image/Image';
import Text from '../../atoms/Text/Text';

const SpeechBubble = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const { t } = useTranslation();
  const { facts } = t('aboutMe', { returnObjects: true });

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? facts.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % facts.length);
  };

  return (
    <div className={styles.SpeechBubble}>
      <div className={styles.description}>
        <Text className={styles.Text}>
          {facts[currentIndex].fact}
        </Text>

        <Image
          src={`/images/${facts[currentIndex].uri}`}
          alt={facts[currentIndex].alt}
          className={styles.Image}
        />
      </div>

      <Chevrons
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </div>
  );
};

export default SpeechBubble;
