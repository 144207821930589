const en = {
  header: {
    languages: {
      swedish: 'Swedish',
      english: 'English'
    },
    logo: 'Logo'
  },
  common: {
    title: 'Grace Chan | Front-End Developer',
    name: 'Grace Chan'
  },
  home: {
    name: 'Home',
    title: 'Front-end Developer',
    inMalmo: 'in Malmö',
    arrow: 'Arrow',
    contacts: [
      {
        name: 'LinkedIn',
        link: 'https://www.linkedin.com/in/yintinggrace/',
        icon: 'fa-brands fa-linkedin',
        openNewTab: true
      },
      {
        name: 'Email',
        link: 'mailto:yintingchancytg@gmail.com',
        icon: 'fa-solid fa-envelope',
        openNewTab: false
      }
    ],
    iSpecialiseIn: 'I specialise in',
    specialization: [
      {
        name: 'React',
        uri: 'react-negative.svg'
      },
      {
        name: 'TypeScript',
        uri: 'typescript-negative.svg'
      },
      {
        name: 'Unit Testing',
        uri: 'unit-testing-negative.svg'
      }
    ]
  },
  aboutMe: {
    name: 'About Me',
    facts: [
      {
        fact: 'Highly motivated React enthusiast crafting user interfaces for an elevated experience.',
        uri: 'react.png',
        alt: 'React'
      },
      {
        fact: 'I love teamwork — it is the key to reaching greater heights and making the journey more enjoyable!',
        uri: 'team.png',
        alt: 'Team'
      },
      {
        fact: 'More than coding—I integrate UX design for well-functioning, stylish websites.',
        uri: 'ui-ux.png',
        alt: 'UI/UX'
      },
      {
        fact: 'I shifted from social work to tech—making an impact is exhilarating!',
        uri: 'computer.png',
        alt: 'Computer'
      },
      {
        fact: 'Daytime code creator, nighttime artsy explorer, on holiday an adventurous traveller.',
        uri: 'fun.png',
        alt: 'Hobbies'
      },
      {
        fact: 'Coffee is cool, but I am sticking to tea all day.',
        uri: 'tea.png',
        alt: 'Tea'
      }
    ]
  },
  skills: {
    name: 'Skills',
    filterBtn: 'Filter',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
    categories: [
      {
        category: 'Web Development Languages',
        content: [
          {
            name: 'C#',
            uri: 'c-sharp.svg'
          },
          {
            name: 'CSS',
            uri: 'css.svg'
          },
          {
            name: 'HTML',
            uri: 'html.svg'
          },
          {
            name: 'JavaScript',
            uri: 'javascript.svg'
          },
          {
            name: 'PHP',
            uri: 'php.svg'
          },
          {
            name: 'TypeScript',
            uri: 'typescript.svg'
          }
        ]
      },
      {
        category: 'JavaScript Frameworks & Libraries',
        content: [
          {
            name: 'D3',
            uri: 'd3.svg'
          },
          {
            name: 'Font Awesome',
            uri: 'font-awesome.svg'
          },
          {
            name: 'i18next',
            uri: 'i18next.svg'
          },
          {
            name: 'Jest',
            uri: 'jest.svg'
          },
          {
            name: 'PropTypes'
          },
          {
            name: 'React',
            uri: 'react.svg'
          },
          {
            name: 'React Router',
            uri: 'react-router.svg'
          }
        ]
      },
      {
        category: 'Styling',
        content: [
          {
            name: 'Responsive Web Design'
          },
          {
            name: 'Sass',
            uri: 'sass.svg'
          },
          {
            name: 'styled-components',
            uri: 'styled-components.svg'
          }
        ]
      },
      {
        category: 'Development Tools',
        content: [
          {
            name: 'ESLint',
            uri: 'eslint.svg'
          },
          {
            name: 'Git',
            uri: 'git.svg'
          },
          {
            name: 'GitHub',
            uri: 'github.svg'
          },
          {
            name: 'Google DevTools'
          },
          {
            name: 'npm',
            uri: 'npm.svg'
          },
          {
            name: 'Visual Studio Code',
            uri: 'vs-code.svg'
          },
          {
            name: 'WordPress',
            uri: 'wordpress.svg'
          }
        ]
      },
      {
        category: 'Design, UX & Prototyping',
        content: [
          {
            name: 'Figma',
            uri: 'figma.svg'
          },
          {
            name: 'Miro',
            uri: 'miro.svg'
          },
          {
            name: 'Personas'
          },
          {
            name: 'Procreate',
            uri: 'procreate.svg'
          },
          {
            name: 'UX & UI Design'
          }
        ]
      },
      {
        category: 'Other Technologies',
        content: [
          {
            name: 'bash',
            uri: 'bash.png'
          },
          {
            name: 'FTP'
          },
          {
            name: 'JSON',
            uri: 'json.svg'
          },
          {
            name: 'Trello',
            uri: 'trello.svg'
          }
        ]
      },
      {
        category: 'General Skills',
        content: [
          {
            name: 'Agile Methodology'
          },
          {
            name: 'Block Element Modifier',
            uri: 'bem.svg'
          },
          {
            name: 'Business Model Canvas'
          }
        ]
      },
      {
        category: 'Languages',
        content: [
          {
            name: 'Swedish',
            uri: 'swedish.png',
            description: 'Fluent'
          },
          {
            name: 'English',
            uri: 'english.png',
            description: 'Fluent'
          },
          {
            name: 'Cantonese',
            uri: 'cantonese.png',
            description: 'Native'
          },
          {
            name: 'Mandarin',
            uri: 'mandarin.png',
            description: 'Fluent'
          }
        ]
      }
    ]
  }
};

export default en;
