import PropTypes from 'prop-types';
import styles from './Contact.module.scss';
import Icon from '../Icon/Icon';

const Contact = ({
  icon, href, name, openNewTab
}) => {
  return (
    <a
      href={href}
      className={styles.Contact}
      title={name}
      target={openNewTab ? '_blank' : ''}
      rel="noreferrer"
    >
      <Icon name={icon} />
    </a>
  );
};

Contact.propTypes = {
  icon: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  openNewTab: PropTypes.bool.isRequired
};

export default Contact;
