import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';

const Checkbox = ({
  checked, indeterminate = false, onChange: handleChange, value
}) => {
  const classNames = `
    ${styles.Checkbox}
    ${indeterminate ? styles.indeterminate : ''}
  `;

  return (
    <input
      type="checkbox"
      className={classNames}
      checked={checked}
      onChange={handleChange}
      value={value}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default Checkbox;
