import PropTypes from 'prop-types';

const Icon = ({ className, name, onClick: handleClick }) => {
  const classNames = `
    ${name}
    ${className || ''}
  `;

  return (
    <i
      className={classNames}
      onClick={handleClick}
    />
  );
};

Icon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default Icon;
