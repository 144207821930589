import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './NavItem.module.scss';

const NavItem = ({
  item,
  page,
  onClick: handleClick,
  isActive
}) => {
  const { i18n } = useTranslation();
  const destination = `${i18n.language === 'en' ? '/en' : ''}${page}`;

  const classNames = `
    ${styles.NavItem}
    ${isActive ? styles.isActive : ''}
  `;

  return (
    <li className={classNames}>
      <Link
        to={destination}
        onClick={handleClick}
      >
        {item}
      </Link>
    </li>
  );
};

NavItem.propTypes = {
  item: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired
};

export default NavItem;
