import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Dropdown.module.scss';
import DropdownList from '../../molecules/DropdownList/DropdownList';
import Icon from '../../atoms/Icon/Icon';

const Dropdown = ({
  handleToggleDropdown,
  handleChangeCheckbox,
  handleSelectAll,
  selectedOptions,
  hasSelectedAll,
  indeterminate,
  options,
  forwardedRef
}) => {
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      (!forwardedRef || !forwardedRef.current.contains(event.target))
    ) {
      handleToggleDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleToggleDropdown]);

  return (
    <div
      className={styles.Dropdown}
      ref={dropdownRef}
    >
      <Icon
        name="fa fa-times"
        className={styles.cross}
        onClick={handleToggleDropdown}
      />

      <DropdownList
        handleChangeCheckbox={handleChangeCheckbox}
        handleSelectAll={handleSelectAll}
        selectedOptions={selectedOptions}
        hasSelectedAll={hasSelectedAll}
        options={options}
        indeterminate={indeterminate}
      />
    </div>
  );
};

Dropdown.propTypes = {
  handleToggleDropdown: PropTypes.func.isRequired,
  handleChangeCheckbox: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasSelectedAll: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ])
};

export default Dropdown;
