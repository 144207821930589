import PropTypes from 'prop-types';
import styles from './Main.module.scss';
import Heading from '../../atoms/Heading/Heading';

const Main = ({
  children, className, title, padded, vCentered
}) => {
  const classNames = `
    ${styles.Main}
    ${className || ''}
    ${padded ? styles.padded : ''}
    ${vCentered ? styles.vCentered : ''}
  `;

  return (
    <main className={classNames}>
      {title ? (
        <Heading
          type="h1"
          className={styles.Heading}
        >
          {title}
        </Heading>
      ) : (
        null
      )}

      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  padded: PropTypes.bool,
  vCentered: PropTypes.bool
};

export default Main;
