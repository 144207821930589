import { useTranslation } from 'react-i18next';
import Contacts from '../../molecules/Contacts/Contacts';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';
import styles from './HeroInfo.module.scss';

const HeroInfo = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.HeroInfo}>
      <Heading
        type="h1"
        className={styles.Heading}
      >
        {t('common.name')}
      </Heading>

      <Text className={styles.Text}>
        {t('home.title')}
      </Text>

      <Text className={styles.Text}>
        {t('home.inMalmo')}
      </Text>

      <Contacts />
    </div>
  );
};

export default HeroInfo;
