import PropTypes from 'prop-types';
import styles from './Skill.module.scss';
import SkillImage from '../../atoms/SkillImage/SkillImage';
import Heading from '../../atoms/Heading/Heading';
import Text from '../../atoms/Text/Text';

const Skill = ({ name, image, description }) => {
  return (
    <div className={styles.Skill}>
      <SkillImage image={image} />

      <Heading
        className={styles.name}
        type="h3"
      >
        {name}
      </Heading>

      {description ? (
        <Text className={styles.description}>
          {description}
        </Text>
      ) : null}
    </div>
  );
};

Skill.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string
};

export default Skill;
