import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './Logo.module.scss';
import Image from '../Image/Image';
import logo from '../../../images/gc-logo.svg';

const Logo = () => {
  const { i18n, t } = useTranslation();
  const destination = `${i18n.language === 'en' ? '/en' : ''}/`;

  return (
    <div className={styles.Logo}>
      <Link to={destination}>
        <Image
          src={logo}
          alt={t('header.logo')}
          className={styles.Image}
        />
      </Link>
    </div>
  );
};

export default Logo;
