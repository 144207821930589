import { useTranslation } from 'react-i18next';
import Image from '../Image/Image';
import styles from './Arrow.module.scss';
import arrow from '../../../images/arrow-down.png';

const Arrow = () => {
  const { t } = useTranslation();

  return (
    <Image
      src={arrow}
      className={styles.Arrow}
      alt={t('home.arrow')}
    />
  );
};

export default Arrow;
