import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Main from '../../components/templates/Main/Main';
import DropdownButton from '../../components/organisms/DropdownButton/DropdownButton';
import SkillCategories from '../../components/organisms/SkillCategories/SkillCategories';
import en from '../../localization/en';

const Skills = ({ setActivePage }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setActivePage(en.skills.name);
  }, []);

  const filtersArray = t('skills.categories', { returnObjects: true });
  const filters = filtersArray.map((element) => element.category);

  const [hasSelectedAll, setHasSelectedAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setHasSelectedAll(false);
    setSelectedOptions([]);
  }, [i18n.language]);

  useEffect(() => {
    if (filters.length === selectedOptions.length) {
      setHasSelectedAll(true);
    }
  }, [selectedOptions]);

  const handleChangeCheckbox = (filter) => {
    if (selectedOptions.includes(filter)) {
      // Uncheck option
      setSelectedOptions(
        selectedOptions.filter((selectedOption) => selectedOption !== filter)
      );
    } else {
      // Check option
      setSelectedOptions([...selectedOptions, filter]);
    }
  };

  const handleSelectAll = () => {
    if (hasSelectedAll) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(filters);
    }

    setHasSelectedAll((prevState) => !prevState);
  };

  return (
    <Main
      title={t('skills.name')}
      padded
    >
      <DropdownButton
        btnText={t('skills.filterBtn')}
        handleChangeCheckbox={handleChangeCheckbox}
        handleSelectAll={handleSelectAll}
        selectedOptions={selectedOptions}
        hasSelectedAll={hasSelectedAll}
        options={filters}
        indeterminate={selectedOptions.length > 0 && selectedOptions.length < filters.length}
      />

      <SkillCategories
        selectedOptions={selectedOptions}
        filters={filters}
      />
    </Main>
  );
};

Skills.propTypes = {
  setActivePage: PropTypes.func.isRequired
};

export default Skills;
