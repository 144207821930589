import PropTypes from 'prop-types';
import styles from './Heading.module.scss';

const Heading = ({ className, children, type }) => {
  const Tag = type;

  return (
    <Tag className={`${styles.Heading} ${className || ''}`}>
      {children}
    </Tag>
  );
};

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']).isRequired
};

export default Heading;
