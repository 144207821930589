import { useTranslation } from 'react-i18next';
import SpecializationItem from '../../atoms/SpecializationItem/SpecializationItem';
import styles from './Specializations.module.scss';
import Text from '../../atoms/Text/Text';

const Specializations = () => {
  const { t } = useTranslation();
  const specializations = t('home.specialization', { returnObjects: true });

  return (
    <div className={styles.Specializations}>
      <Text className={styles.Text}>
        {t('home.iSpecialiseIn')}
      </Text>

      <div className={styles.container}>
        {specializations.map((element) => (
          <SpecializationItem
            key={element.name}
            name={element.name}
            image={element.uri}
          />
        ))}
      </div>
    </div>
  );
};

export default Specializations;
