import { useTranslation } from 'react-i18next';
import styles from './Languages.module.scss';
import flagSweden from '../../../images/flag-sweden.svg';
import flagUk from '../../../images/flag-uk.svg';
import Language from '../../atoms/Language/Language';

const Languages = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Languages}>
      <Language
        src={flagSweden}
        alt={t('header.languages.swedish')}
        title={t('header.languages.swedish')}
        lang="sv"
      />
      <Language
        src={flagUk}
        alt={t('header.languages.english')}
        title={t('header.languages.english')}
        lang="en"
      />
    </div>
  );
};

export default Languages;
