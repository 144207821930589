import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DropdownListItem from '../DropdownListItem/DropdownListItem';
import styles from './DropdownList.module.scss';

const DropdownList = ({
  handleChangeCheckbox,
  handleSelectAll,
  selectedOptions,
  hasSelectedAll,
  indeterminate,
  options
}) => {
  const { t } = useTranslation();

  return (
    <ul className={styles.DropdownList}>
      <DropdownListItem
        checked={hasSelectedAll}
        indeterminate={indeterminate}
        onChange={handleSelectAll}
        text={hasSelectedAll ? t('skills.unselectAll') : t('skills.selectAll')}
      />

      {options.map((option) => (
        <DropdownListItem
          checked={selectedOptions.includes(option)}
          onChange={() => handleChangeCheckbox(option)}
          text={option}
          option={option}
          key={option}
        />
      ))}
    </ul>
  );
};

DropdownList.propTypes = {
  handleChangeCheckbox: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasSelectedAll: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DropdownList;
