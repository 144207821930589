import PropTypes from 'prop-types';
import Text from '../Text/Text';
import styles from './SpecializationItem.module.scss';
import Image from '../Image/Image';

const SpecializationItem = ({ name, image }) => {
  return (
    <div className={styles.SpecializationItem}>
      <Image
        className={styles.Image}
        src={`/images/${image}`}
        alt={name}
        title={name}
      />

      <Text className={styles.Text}>
        {name}
      </Text>
    </div>
  );
};

SpecializationItem.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
};

export default SpecializationItem;
