import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './Language.module.scss';
import Image from '../Image/Image';

const Language = ({
  src, alt, title, lang
}) => {
  const { i18n } = useTranslation();
  const history = useHistory();

  const changeLanguage = () => {
    const path = `/${lang === 'en' ? 'en' : ''}`;

    i18n.changeLanguage(lang);
    history.push(path);
    document.title = i18n.t('common.title');
  };

  return (
    <a onClick={changeLanguage}>
      <Image
        src={src}
        alt={alt}
        title={title}
        className={styles.Language}
      />
    </a>
  );
};

Language.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired
};

export default Language;
