import PropTypes from 'prop-types';

const Image = ({
  src, alt, className, title = ''
}) => {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      title={title}
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.string
};

export default Image;
