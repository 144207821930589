import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import Main from '../../components/templates/Main/Main';
import styles from './AboutMe.module.scss';
import grace from '../../images/grace-about-me.png';
import Image from '../../components/atoms/Image/Image';
import SpeechBubble from '../../components/organisms/SpeechBubble/SpeechBubble';
import en from '../../localization/en';

const AboutMe = ({ setActivePage }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setActivePage(en.aboutMe.name);
  }, []);

  return (
    <Main
      title={t('aboutMe.name')}
      padded
      vCentered
    >
      <div className={styles.container}>
        <Image
          src={grace}
          alt={t('common.name')}
          className={styles.Image}
        />

        <SpeechBubble />
      </div>
    </Main>
  );
};

AboutMe.propTypes = {
  setActivePage: PropTypes.func.isRequired
};

export default AboutMe;
