import { useTranslation } from 'react-i18next';
import Image from '../../atoms/Image/Image';
import HeroInfo from '../HeroInfo/HeroInfo';
import Arrow from '../../atoms/Arrow/Arrow';
import grace from '../../../images/grace-home.png';
import graceMobile from '../../../images/grace-home-mobile.png';
import styles from './Hero.module.scss';

const Hero = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.Hero}>
      <div className={styles.Circle1} />

      <Image
        src={grace}
        alt={t('common.name')}
        className={styles.Image}
      />

      <Image
        src={graceMobile}
        alt={t('common.name')}
        className={styles.ImageMobile}
      />

      <HeroInfo />

      <div className={styles.Circle2} />

      <Arrow />
    </div>
  );
};

export default Hero;
