import { useEffect } from 'react';
import PropTypes from 'prop-types';
import en from '../../localization/en';
import Main from '../../components/templates/Main/Main';
import Specializations from '../../components/molecules/Specializations/Specializations';
import Hero from '../../components/organisms/Hero/Hero';

const Home = ({ setActivePage }) => {
  useEffect(() => {
    setActivePage(en.home.name);
  }, []);

  return (
    <Main>
      <Hero />
      <Specializations />
    </Main>
  );
};

Home.propTypes = {
  setActivePage: PropTypes.func.isRequired
};

export default Home;
