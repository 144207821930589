import PropTypes from 'prop-types';
import Chevron from '../../atoms/Chevron/Chevron';
import styles from './Chevrons.module.scss';

const Chevrons = ({ onPrevious, onNext }) => {
  return (
    <div className={styles.Chevrons}>
      <Chevron
        name="fa-solid fa-angle-up"
        onClick={onPrevious}
      />
      <Chevron
        name="fa-solid fa-angle-down"
        onClick={onNext}
      />
    </div>
  );
};

Chevrons.propTypes = {
  onPrevious: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default Chevrons;
