import PropTypes from 'prop-types';
import styles from './Chevron.module.scss';
import Icon from '../Icon/Icon';

const Chevron = ({ name, onClick: handleClick }) => {
  return (
    <button
      className={styles.Chevron}
      onClick={handleClick}
      type="button"
    >
      <Icon name={name} />
    </button>
  );
};

Chevron.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default Chevron;
