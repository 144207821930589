import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import styles from './HamburgerIcon.module.scss';

const HamburgerIcon = ({
  isToggled,
  onClick: handleClick
}) => {
  const name = isToggled ? 'fa fa-times' : 'fa fa-bars';

  return (
    <Icon
      name={name}
      className={styles.Hamburger}
      onClick={handleClick}
    />
  );
};

HamburgerIcon.propTypes = {
  isToggled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default HamburgerIcon;
