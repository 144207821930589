import PropTypes from 'prop-types';

const Text = ({ children, className }) => {
  return (
    <div className={className || ''}>
      {children}
    </div>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Text;
