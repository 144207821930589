import PropTypes from 'prop-types';
import Heading from '../../atoms/Heading/Heading';
import Skill from '../../molecules/Skill/Skill';
import styles from './SkillCategory.module.scss';

const SkillCategory = ({ name, content }) => {
  return (
    <div className={styles.SkillCategory}>
      <Heading
        type="h2"
        className={styles.Heading}
      >
        {name}
      </Heading>

      <div className={styles.content}>
        {content.map((skill) => (
          <Skill
            name={skill.name}
            image={skill.uri}
            description={skill.description}
            key={skill.name}
          />
        ))}
      </div>
    </div>
  );
};

SkillCategory.propTypes = {
  name: PropTypes.string.isRequired,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string
    })
  ).isRequired
};

export default SkillCategory;
