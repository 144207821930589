import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SkillCategory from '../SkillCategory/SkillCategory';

const SkillCategories = ({ selectedOptions, filters }) => {
  const { t } = useTranslation();
  const displayFilters = selectedOptions.length === 0 ? filters : selectedOptions;

  return (
    <div>
      {displayFilters.map((filter) => {
        const categories = t('skills.categories', { returnObjects: true }) || [];
        const category = categories.find((cat) => cat && cat.category === filter);

        if (!category) {
          return null;
        }

        return (
          <SkillCategory
            name={category.category}
            content={category.content}
            key={category.category}
          />
        );
      })}
    </div>
  );
};

SkillCategories.propTypes = {
  selectedOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SkillCategories;
