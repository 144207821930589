import PropTypes from 'prop-types';
import styles from './CheckboxGroup.module.scss';
import Checkbox from '../../atoms/Checkbox/Checkbox';

const CheckboxGroup = ({
  checked, indeterminate, onChange: handleChange, text, value
}) => {
  return (
    <label className={styles.CheckboxGroup}>
      {text}

      <span className={styles.checkboxWrapper}>
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleChange}
          value={value}
        />
      </span>
    </label>
  );
};

CheckboxGroup.propTypes = {
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default CheckboxGroup;
