import { useTranslation } from 'react-i18next';
import styles from './Contacts.module.scss';
import Contact from '../../atoms/Contact/Contact';

const Contacts = () => {
  const { t } = useTranslation();
  const contacts = t('home.contacts', { returnObjects: true });

  return (
    <div className={styles.Contacts}>
      {contacts.map((element) => (
        <Contact
          icon={element.icon}
          href={element.link}
          name={element.name}
          openNewTab={element.openNewTab}
          key={element.name}
        />
      ))}
    </div>
  );
};

export default Contacts;
